import { Code, CodeProps } from "@chakra-ui/react";
import {
  lineNumbers as codeMirrorLineNumbers,
  placeholder as codeMirrorPlaceholder,
} from "@codemirror/view";
import {
  addExtension,
  addUpdateListener,
  clearExtensions,
} from "@codemirror-toolkit/extensions";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";

import { FocusableElement } from "~/utils/focusableElement";

import { EditorCommand, EditorEvent, keymapHandler } from "./keymaps";
import { useContainerRef, useView, useViewEffect } from "./provider";
import safariCopyPasteHackExtension, {
  enableSafariCopyPasteHackExtension,
} from "./safariHackExt";

export interface EditorProps {
  autoFocus?: boolean;
  containerProps?: CodeProps;
  placeholder?: string;
  onChange?: (newValue: string) => void;
  onCommand?: (command: EditorCommand) => boolean;
  onKeyDown?: (event: EditorEvent) => boolean;
  lineNumbers?: boolean;
}

function useAdditionalExtensions({
  lineNumbers,
  onCommand,
  onKeyDown,
  placeholder,
}: Pick<
  EditorProps,
  "lineNumbers" | "placeholder" | "onCommand" | "onKeyDown"
>) {
  const initialized = useRef(false);
  const view = useView();

  const keymapHandlerExt = useCallback(
    () => keymapHandler(onKeyDown, onCommand),
    [onKeyDown, onCommand],
  );
  const placeholderExt = useMemo(
    () => codeMirrorPlaceholder(placeholder ?? ""),
    [placeholder],
  );

  useEffect(() => {
    if (!view || initialized.current) return;
    addExtension(view, keymapHandlerExt());
    if (lineNumbers) {
      addExtension(view, codeMirrorLineNumbers());
    }
    if (placeholder) {
      addExtension(view, placeholderExt);
    }
    if (enableSafariCopyPasteHackExtension) {
      addExtension(view, safariCopyPasteHackExtension());
    }
    initialized.current = true;
    return () => {
      clearExtensions(view);
      initialized.current = false;
    };
  }, [view, keymapHandlerExt, lineNumbers, placeholder, placeholderExt]);
}

const Editor = forwardRef<FocusableElement, EditorProps>(
  (
    {
      autoFocus = true,
      containerProps,
      onChange,
      onCommand,
      onKeyDown,
      placeholder,
      lineNumbers,
    },
    ref,
  ) => {
    const currentView = useView();
    const containerRef = useContainerRef();
    useViewEffect((view) => {
      return addUpdateListener(view, (update) => {
        if (onChange && update.docChanged) {
          onChange(update.state.doc.toString());
        }
      });
    });
    useAdditionalExtensions({ lineNumbers, placeholder, onCommand, onKeyDown });

    useImperativeHandle(ref, () => ({
      focus(_options?: FocusOptions) {
        if (!currentView) return;
        currentView.focus();
      },
    }));

    useEffect(() => {
      if (!currentView) return;
      if (autoFocus) {
        currentView.focus();
      }
    }, [currentView, autoFocus]);

    return (
      <Code {...(containerProps ?? {})} cursor="text" tabIndex={0}>
        <div ref={containerRef} />
      </Code>
    );
  },
);

export default Editor;
