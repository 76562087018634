import {
  Button,
  FormControl,
  Input,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import Alert from "~/components/Alert";
import { LabeledInput } from "~/components/formComponentsV2";
import TextLink from "~/components/TextLink";
import { AuthContentContainer, AuthLayout } from "~/layouts/AuthLayout";
import { signupPath } from "~/platform/routeHelpers";
import { useLogin, usePrelogin } from "~/queries/frontegg";

type LoginFormState = {
  email: string;
  showPassword: boolean;
  password: string;
};

export const Login = () => {
  const navigate = useNavigate();
  const [searchParms] = useSearchParams();
  const redirectUrl = searchParms.get("redirectUrl") || "/";
  const { formState, handleSubmit, register, watch, setValue } =
    useForm<LoginFormState>({
      defaultValues: {
        email: "",
        showPassword: false,
        password: "",
      },
      mode: "onTouched",
    });
  const {
    error,
    isPending: isLoginPending,
    mutate: login,
  } = useLogin({
    onSuccess: () => {
      navigate(redirectUrl, { replace: true });
    },
  });
  const email = watch("email");
  const showPassword = watch("showPassword");
  const {
    data: sso,
    error: preloginError,
    isPending: isPreloginPending,
    mutate: prelogin,
  } = usePrelogin({});
  React.useEffect(() => {
    if (sso) {
      window.location.href = sso.address;
      return;
    } else if (sso === false) {
      setValue("showPassword", true);
    }
  }, [setValue, sso]);
  const handleValidSubmit = (values: LoginFormState) => {
    if (!values.password) {
      prelogin({ email });
      return;
    }
    login(values);
  };
  const submitButtonDisabled = Boolean(
    // While we are making requests, or if we are redirecting to an SSO provider.
    isLoginPending || isPreloginPending || sso,
  );

  return (
    <AuthLayout>
      <AuthContentContainer
        title="Sign in"
        subheading={
          <Text>
            New to Materialize?{" "}
            <TextLink as={Link} to={signupPath}>
              Sign Up
            </TextLink>
          </Text>
        }
      >
        <form onSubmit={handleSubmit(handleValidSubmit)}>
          <VStack spacing="6" alignItems="start" textAlign="center">
            {(error || preloginError) && (
              <Alert variant="error" message="Login failed" width="100%" />
            )}
            <FormControl isInvalid={!!formState.errors.email}>
              <LabeledInput
                label="Email"
                error={formState.errors.email?.message}
                variant="stretch"
              >
                <Input
                  {...register("email", {
                    required: "Email is required.",
                  })}
                  autoCorrect="off"
                  placeholder="name@example.com"
                  size="lg"
                  variant={formState.errors.email ? "error" : "default"}
                />
              </LabeledInput>
            </FormControl>
            {showPassword && (
              <FormControl isInvalid={!!formState.errors.password}>
                <LabeledInput
                  label="Password"
                  error={formState.errors.password?.message}
                  variant="stretch"
                >
                  <Input
                    {...register("password", {
                      required: "Password is required.",
                    })}
                    autoCorrect="off"
                    placeholder="Enter your password"
                    size="lg"
                    type="password"
                    variant={formState.errors.password ? "error" : "default"}
                  />
                </LabeledInput>
              </FormControl>
            )}

            <Button
              variant="primary"
              size="lg"
              type="submit"
              isLoading={submitButtonDisabled}
              spinner={<Spinner />}
              width="100%"
            >
              {showPassword ? "Sign in" : "Continue"}
            </Button>
          </VStack>
        </form>
      </AuthContentContainer>
    </AuthLayout>
  );
};
