import {
  DefaultError,
  useMutation,
  UseMutationOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import React from "react";

import { useCanAccessTenantApiTokens } from "~/api/auth";
import {
  buildGlobalQueryKey,
  buildQueryKeyPart,
} from "~/api/buildQueryKeySchema";
import {
  acceptInvitation,
  activateAccount,
  activateStrategy,
  createTenantApiToken,
  createUserApiToken,
  deleteTenantApiToken,
  deleteUserApiToken,
  fetchRefreshAccessToken,
  fetchRoles,
  fetchTenantApiTokens,
  fetchTenants,
  fetchUser,
  fetchUserApiTokens,
  login,
  logout,
  oidcPostlogin,
  prelogin,
  signup,
} from "~/api/frontegg";
import {
  ActivateAccountResponse,
  ActivateStrategyParams,
  ApiToken,
  LoginSuccess,
  NewApiToken,
  PreloginResult,
  SignupParams,
  SignupResponse,
  Tenant,
  TenantApiToken,
  TenantsResponse,
  User,
  UserApiToken,
} from "~/api/frontegg/types";
import { getFronteggToken, setFronteggToken } from "~/api/fronteggToken";
import config from "~/config";
import { isAuthRoute } from "~/fronteggRoutes";
import { queryClient } from "~/queryClient";

export const fronteggQueryKeys = {
  all: () => buildGlobalQueryKey("frontegg"),
  acceptInvitation: () =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("acceptInvitation"),
    ] as const,
  activateAccount: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("activateAccount")] as const,
  activateStrategy: (params: ActivateStrategyParams) =>
    [
      ...fronteggQueryKeys.all(),
      buildQueryKeyPart("activateStrategy"),
      params,
    ] as const,
  user: () => [...fronteggQueryKeys.all(), buildQueryKeyPart("user")] as const,
  roles: (params: { enabled: boolean }) =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("roles"), params] as const,
  apiTokens: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("api-tokens")] as const,
  listApiTokens: (params: { canAccessTenantApiTokens: boolean }) =>
    [...fronteggQueryKeys.apiTokens(), params] as const,
  createApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("create")] as const,
  deleteApiToken: () =>
    [...fronteggQueryKeys.apiTokens(), buildQueryKeyPart("delete")] as const,
  isLoggedIn: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("isLoggedIn")] as const,
  login: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("login")] as const,
  logout: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("logout")] as const,
  prelogin: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("prelogin")] as const,
  signup: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("signup")] as const,
  tenants: () =>
    [...fronteggQueryKeys.all(), buildQueryKeyPart("tenants")] as const,
};

function buildFakeUser(overrides?: Partial<User>): User {
  return {
    id: "1",
    name: "Local User",
    email: "local@materialize.com",
    tenantId: "123",
    profilePictureUrl: "",
    sub: "",
    verified: true,
    phoneNumber: null,
    provider: "",
    mfaEnrolled: false,
    metadata: "",
    vendorMetadata: null,
    tenantIds: [],
    roles: [],
    permissions: [],
    createdAt: "",
    lastLogin: "",
    isLocked: false,
    tenants: [],
    managedBy: "frontegg",
    groups: [],
    subAccountAccessAllowed: false,
    activatedForTenant: true,
    ...overrides,
  };
}

export function queryUser(requestOptions?: RequestInit) {
  if (config.impersonation) {
    return buildFakeUser({
      name: "Impersonation User",
      email: "impersonation@materialize.com",
      tenantId: config.impersonation.organizationId,
      tenantIds: [config.impersonation.organizationId],
    });
  }
  if (config.environmentdOverride) {
    return buildFakeUser();
  }

  return fetchUser(requestOptions);
}

function buildFakeTenant(overrides?: Partial<Tenant>) {
  return {
    _id: "1",
    vendorId: "1",
    tenantId: "123",
    name: "1",
    deletedAt: null,
    metadata: "",
    isReseller: false,
    creatorEmail: "user@materialize.com",
    creatorName: "user",
    id: "1",
    createdAt: new Date(),
    updatedAt: new Date(),
    __v: 1,
    ...overrides,
  };
}

export async function queryTenants(
  requestOptions?: RequestInit,
): Promise<TenantsResponse> {
  if (config.environmentdOverride) {
    return { tenants: [buildFakeTenant()], activeTenant: buildFakeTenant() };
  }
  return fetchTenants(requestOptions);
}

export function getUser() {
  return queryClient.fetchQuery({
    queryKey: fronteggQueryKeys.user(),
    staleTime: Infinity,
    queryFn: ({ signal }) => queryUser({ signal }),
  });
}

export function useAcceptInvitation(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { userId: string; token: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.acceptInvitation(),
    mutationFn: acceptInvitation,
    ...options,
  });
}

export function useActivateAccount(
  options: UseMutationOptions<
    ActivateAccountResponse,
    DefaultError,
    { userId: string; token: string; password?: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.activateAccount(),
    mutationFn: async (params) => {
      const response = await activateAccount(params);
      setFronteggToken(response.accessToken);
      return response;
    },
    ...options,
  });
}

export function useActivateStrategy(params: ActivateStrategyParams) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.activateStrategy(params),
    queryFn: ({ queryKey: [, , queryKeyParams], signal }) =>
      activateStrategy(queryKeyParams, { signal }),
  });
}

export function useCurrentUser() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.user(),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    queryFn: ({ signal }) => queryUser({ signal }),
  });
}

export function useRoles(params: { enabled: boolean } = { enabled: true }) {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.roles(params),
    queryFn: ({ signal }) => (params.enabled ? fetchRoles({ signal }) : []),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}

export function useListApiTokens() {
  const canAccessTenantApiTokens = useCanAccessTenantApiTokens();
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.listApiTokens({ canAccessTenantApiTokens }),
    queryFn: async ({ signal }) => {
      const fetches: Promise<UserApiToken[] | TenantApiToken[]>[] = [
        fetchUserApiTokens({ signal }),
      ];
      if (canAccessTenantApiTokens) {
        fetches.push(fetchTenantApiTokens({ signal }));
      }
      const apiTokens = await Promise.all(fetches);
      return apiTokens
        .flat()
        .toSorted((x, y) => Date.parse(y.createdAt) - Date.parse(x.createdAt));
    },
  });
}

type CreateApiTokenVariables =
  | {
      type: "personal";
      description: string;
    }
  | {
      type: "service";
      description: string;
      user: string;
      roleIds: string[];
    };

function formatAppPassword({ clientId, secret }: NewApiToken) {
  const formattedClientId = clientId.replaceAll("-", "");
  const formattedSecret = secret.replaceAll("-", "");
  const password = `mzp_${formattedClientId}${formattedSecret}`;
  const obfuscatedPassword = `${new Array(password.length).fill("*").join("")}`;
  return { password, obfuscatedPassword };
}

export function useCreateApiToken(
  options?: UseMutationOptions<
    NewApiToken,
    DefaultError,
    CreateApiTokenVariables
  >,
) {
  const mutation = useMutation({
    mutationKey: fronteggQueryKeys.createApiToken(),
    mutationFn: async (params: CreateApiTokenVariables) => {
      if (params.type === "personal") {
        return await createUserApiToken(params);
      } else {
        return await createTenantApiToken(params);
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });

  const newPassword = React.useMemo(() => {
    if (!mutation.data) return null;

    const { password, obfuscatedPassword } = formatAppPassword(mutation.data);
    return { ...mutation.data, password, obfuscatedPassword };
  }, [mutation.data]);

  return {
    ...mutation,
    data: newPassword,
  };
}

export function useDeleteApiToken(
  options: UseMutationOptions<Response, DefaultError, { token: ApiToken }> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.deleteApiToken(),
    mutationFn: async (params: { token: ApiToken }) => {
      if (params.token.type === "personal") {
        return await deleteUserApiToken({ id: params.token.clientId });
      } else {
        return await deleteTenantApiToken({ id: params.token.clientId });
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: fronteggQueryKeys.apiTokens(),
      });
    },
    ...options,
  });
}

export function useLogout(options: UseMutationOptions = {}) {
  return useMutation({
    mutationKey: fronteggQueryKeys.logout(),
    mutationFn: async () => logout(),
    ...options,
  });
}

export function useOidcLogin(
  options: UseMutationOptions<
    Response,
    DefaultError,
    { code: string; state: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.login(),
    mutationFn: oidcPostlogin,
    ...options,
  });
}

export function useLogin(
  options: UseMutationOptions<
    LoginSuccess,
    DefaultError,
    { email: string; password: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.login(),
    mutationFn: async (params) => {
      const result = await login(params);
      setFronteggToken(result.accessToken);
      return result;
    },
    ...options,
  });
}

export function useIsLoggedIn() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.isLoggedIn(),
    queryFn: async () => {
      if (config.isImpersonating) return true;
      if (getFronteggToken()) return true;
      if (isAuthRoute()) return false;

      try {
        const response = await fetchRefreshAccessToken();
        setFronteggToken(response.accessToken);
        return true;
      } catch {
        return false;
      }
    },
  });
}

export function usePrelogin(
  options: UseMutationOptions<
    PreloginResult,
    DefaultError,
    { email: string }
  > = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.prelogin(),
    mutationFn: (params) => {
      return prelogin(params);
    },
    ...options,
  });
}

export function useSignup(
  options: UseMutationOptions<SignupResponse, DefaultError, SignupParams> = {},
) {
  return useMutation({
    mutationKey: fronteggQueryKeys.signup(),
    mutationFn: signup,
    ...options,
  });
}

export function useTenants() {
  return useSuspenseQuery({
    queryKey: fronteggQueryKeys.tenants(),
    queryFn: (requestOptions) => queryTenants(requestOptions),
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
}
