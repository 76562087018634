import {
  Box,
  Code,
  Flex,
  Grid,
  GridItem,
  HStack,
  ListItem,
  Spinner,
  Text,
  UnorderedList,
  useTheme,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import { Cluster } from "~/api/materialize/cluster/clusterList";
import useClusterUtilization, {
  ReplicaUtilization,
} from "~/api/materialize/cluster/useClusterUtilization";
import Alert from "~/components/Alert";
import ErrorBox from "~/components/ErrorBox";
import { EventEmitterProvider } from "~/components/EventEmitterContext";
import LabeledSelect from "~/components/LabeledSelect";
import TimePeriodSelect from "~/components/TimePeriodSelect";
import { useTimePeriodMinutes } from "~/hooks/useTimePeriodSelect";
import { MainContentContainer } from "~/layouts/BaseLayout";
import { useAllClusters } from "~/store/allClusters";
import { MaterializeTheme } from "~/theme";
import { assert } from "~/util";

import { DataPoint, OfflineEvent } from "./ClusterOverview/types";
import {
  TOTAL_GRAPH_HEIGHT_PX,
  UtilizationGraph,
} from "./ClusterOverview/UtilizationGraph";
import { ClusterParams } from "./ClusterRoutes";
import { CLUSTERS_FETCH_ERROR_MESSAGE } from "./constants";
import LargestMaintainedQueries from "./LargestMaintainedQueries";

export interface ReplicaData {
  id: string;
  data: DataPoint[];
}

// because the data is sampled on 60s intervals, we don't want to show more granular data than this.
const minBucketSizeMs = 60 * 1000;

const GRAPH_SPACING = 24;

const ClusterOverview = () => {
  const { colors } = useTheme<MaterializeTheme>();
  const { clusterId } = useParams<ClusterParams>();

  assert(clusterId);
  const { getClusterById } = useAllClusters();
  const cluster = getClusterById(clusterId);
  const [timePeriodMinutes, setInitialTimePeriodMinutes] = useTimePeriodMinutes(
    {
      localStorageKey: "mz-cluster-graph-time-period",
    },
  );
  const [selectedReplica, setSelectedReplica] = React.useState("all");

  const {
    reset,
    currentStartTime,
    currentEndTime,
    data: rawReplicaUtilization,
    error,
    snapshotComplete,
  } = useClusterUtilization({
    clusterId,
    replicaId: selectedReplica === "all" ? undefined : selectedReplica,
    timePeriodMinutes,
  });

  // We bucket the data to improve performance of longer timespans
  const bucketSizeMs = React.useMemo(
    () => Math.max(timePeriodMinutes * 1000, minBucketSizeMs),
    [timePeriodMinutes],
  );

  // An array of timestamps that represents the buckets we actually display on the graph
  const buckets = React.useMemo(() => {
    const startTimestamp = currentStartTime.getTime();
    const result = [];
    let currentBucket = startTimestamp;
    while (currentBucket < currentEndTime.getTime()) {
      result.push(currentBucket);
      currentBucket += bucketSizeMs;
    }
    return result;
  }, [bucketSizeMs, currentStartTime, currentEndTime]);

  type ReplicaId = string;
  type Timestamp = number;
  type ReplicaMap = Map<ReplicaId, ReplicaUtilization[]>;

  // We don't want to render every datapoint because it doesn't perform well on large
  // timespans so we bucket the data. We decide on the bucket size by the total timespan
  // we are displaying so that it scales nicely.
  const graphData = React.useMemo(() => {
    if (!cluster) return undefined;

    // First we build a nested map of timestamps to replica and their data
    const bucketMap = new Map<Timestamp, ReplicaMap>();
    for (const datum of rawReplicaUtilization) {
      const bucket = buckets.find(
        (b) =>
          // greater than the start of the bucket, less than the end
          datum.timestamp >= b && datum.timestamp <= b + bucketSizeMs,
      );
      if (!bucket || !datum.id) {
        continue;
      }
      const replicaMap = bucketMap.get(bucket);

      if (replicaMap) {
        const replicaBucket = replicaMap.get(datum.id);
        if (replicaBucket) {
          replicaBucket.push(datum);
        } else {
          replicaMap.set(datum.id, [datum]);
        }
      } else {
        bucketMap.set(bucket, new Map([[datum.id, [datum]]]));
      }
    }
    const chartLinesMap = new Map<string, DataPoint[]>();
    // Now we are ready to pull out the actual data from each bucket we want to display
    // which is max value of each metric observed during that bucket.
    // We also keep track of all the not ready events so we can show exact timestamps in
    // the tooltip.
    for (const [bucket, replicaMap] of bucketMap.entries()) {
      for (const replicaId of replicaMap.keys()) {
        const lineData: DataPoint[] = chartLinesMap.get(replicaId) ?? [];
        const utilizations = replicaMap.get(replicaId);
        if (!utilizations) {
          continue;
        }
        const name = utilizations[0].name;
        const size = utilizations[0].size;
        let maxCpu = utilizations[0].cpuPercent;
        let maxMemory = utilizations[0].memoryPercent;
        let maxDisk = utilizations[0].diskPercent;
        let offlineReason = utilizations[0].offlineReason;
        const offlineEvents: OfflineEvent[] = [];
        for (const value of utilizations) {
          if (!maxCpu || (value.cpuPercent && value.cpuPercent > maxCpu)) {
            maxCpu = value.cpuPercent;
          }
          if (
            !maxMemory ||
            (value.memoryPercent && value.memoryPercent > maxMemory)
          ) {
            maxMemory = value.memoryPercent;
          }
          if (
            !maxDisk ||
            (value.memoryPercent && value.memoryPercent > maxDisk)
          ) {
            maxDisk = value.diskPercent;
          }
          if (!offlineReason && value.offlineReason) {
            offlineReason = value.offlineReason;
          }
          if (value.status === "not-ready" || value.status === "offline") {
            offlineEvents.push({
              id: replicaId,
              offlineReason: value.offlineReason,
              status: value.status,
              timestamp: value.timestamp,
            });
          }
        }
        const bucketValue: DataPoint = {
          id: replicaId,
          name,
          size,
          bucketStart: bucket,
          bucketEnd: bucket + bucketSizeMs,
          cpuPercent: maxCpu,
          memoryPercent: maxMemory,
          diskPercent: maxDisk,
          offlineReason: offlineReason,
          offlineEvents: offlineEvents.reverse(),
        };
        lineData.push(bucketValue);
        chartLinesMap.set(replicaId, lineData);
      }
    }
    const chartData: ReplicaData[] = [];
    for (const [id, data] of chartLinesMap) {
      chartData.push({ id, data });
    }
    return chartData;
  }, [bucketSizeMs, buckets, cluster, rawReplicaUtilization]);

  const currentReplicas = React.useMemo(() => {
    return new Set(cluster?.replicas.map((r) => r.id));
  }, [cluster?.replicas]);

  const replicaColorMap = React.useMemo(() => {
    return new Map(
      (graphData ?? []).map((d, i) => {
        const replica = d.data[0];
        const label = currentReplicas.has(replica.id)
          ? replica.name
          : replica.name + " (dropped)";
        return [
          d.id,
          { label, color: colors.lineGraph[i % colors.lineGraph.length] },
        ];
      }),
    );
  }, [colors.lineGraph, currentReplicas, graphData]);

  const offlineEvents = React.useMemo(() => {
    const result: Array<OfflineEvent> = [];
    if (!rawReplicaUtilization) return result;
    for (const {
      id,
      status,
      offlineReason: offlineReason,
      timestamp,
    } of rawReplicaUtilization) {
      if (
        (status === "not-ready" || status === "offline") &&
        offlineReason !== "oom-killed"
      ) {
        result.push({
          id,
          offlineReason: offlineReason,
          status,
          timestamp,
        });
      }
    }
    return result;
  }, [rawReplicaUtilization]);

  // Because sockets often disconnect for all sorts of reasons
  // we really only want to show an error if we actually failed to load data
  const showSocketError = error && !snapshotComplete;
  if (showSocketError) {
    return <ErrorBox message={CLUSTERS_FETCH_ERROR_MESSAGE} />;
  }

  // The snapshot includes all the historical data, but we can start graphing it once we
  // have any data. In the case there is no data to show, snapshotComplete will be true,
  // even though data is empty.
  const graphDataLoaded =
    (graphData && graphData.length > 0) || snapshotComplete;

  // It's possible to have a cluster where only some replicas have disk. Also, we still
  // want to show the graph for managed clusters that have disk, but no replicas.
  const clusterHasDisk =
    Boolean(cluster?.disk) || Boolean(cluster?.replicas.some((r) => r.disk));
  const graphContainerHeight = clusterHasDisk
    ? TOTAL_GRAPH_HEIGHT_PX * 2 + GRAPH_SPACING
    : TOTAL_GRAPH_HEIGHT_PX;
  const isLoading = !graphDataLoaded || !graphData;

  return (
    <MainContentContainer mt="10">
      <VStack spacing="6">
        {cluster && <ClusterInfoBox cluster={cluster} />}
        <Box
          border={`solid 1px ${colors.border.primary}`}
          borderRadius="8px"
          py={4}
          px={6}
          width="100%"
          minW="460px"
        >
          <Flex
            width="100%"
            alignItems="start"
            justifyContent="space-between"
            mb="6"
          >
            <Text as="h3" fontSize="18px" lineHeight="20px" fontWeight={500}>
              Resource Usage
            </Text>
            <HStack>
              {cluster && (
                <LabeledSelect
                  label="Replicas"
                  value={selectedReplica}
                  onChange={(e) => setSelectedReplica(e.target.value)}
                >
                  <option value="all">All</option>
                  {cluster.replicas.map((r) => (
                    <option key={r.id} value={r.id}>
                      {r.name}
                    </option>
                  ))}
                </LabeledSelect>
              )}
              <TimePeriodSelect
                timePeriodMinutes={timePeriodMinutes}
                setTimePeriodMinutes={(timePeriod) => {
                  setInitialTimePeriodMinutes(timePeriod);
                  // if we don't reset here, there is a flash of the old data on
                  // the new x-axis, which looks janky.
                  reset();
                }}
              />
            </HStack>
          </Flex>
          {isLoading ? (
            <Flex
              height={graphContainerHeight}
              width="100%"
              alignItems="center"
              justifyContent="center"
            >
              <Spinner data-testid="loading-spinner" />
            </Flex>
          ) : (
            <Grid
              gridTemplateColumns={{
                base: "minmax(100px, 1fr)",
                lg: "repeat(2, minmax(100px, 1fr))",
              }}
              gap={GRAPH_SPACING + "px"}
            >
              <EventEmitterProvider>
                <GridItem alignItems="flex-start" gap={6} width="100%">
                  <UtilizationGraph
                    bucketSizeMs={bucketSizeMs}
                    dataKey="cpuPercent"
                    data={graphData}
                    startTime={currentStartTime}
                    endTime={currentEndTime}
                    offlineEvents={offlineEvents}
                    timePeriodMinutes={timePeriodMinutes}
                    replicaColorMap={replicaColorMap}
                    title="CPU"
                  />
                </GridItem>
                <GridItem>
                  <UtilizationGraph
                    bucketSizeMs={bucketSizeMs}
                    dataKey="memoryPercent"
                    data={graphData}
                    startTime={currentStartTime}
                    endTime={currentEndTime}
                    offlineEvents={offlineEvents}
                    timePeriodMinutes={timePeriodMinutes}
                    replicaColorMap={replicaColorMap}
                    title="Memory"
                  />
                </GridItem>
                {clusterHasDisk && (
                  <GridItem>
                    <UtilizationGraph
                      bucketSizeMs={bucketSizeMs}
                      dataKey="diskPercent"
                      data={graphData}
                      startTime={currentStartTime}
                      endTime={currentEndTime}
                      offlineEvents={offlineEvents}
                      timePeriodMinutes={timePeriodMinutes}
                      replicaColorMap={replicaColorMap}
                      title="Disk"
                    />
                  </GridItem>
                )}
              </EventEmitterProvider>
            </Grid>
          )}
        </Box>
        {cluster && (
          <Box width="100%">
            <LargestMaintainedQueries
              clusterId={cluster.id}
              clusterName={cluster.name}
            />
          </Box>
        )}
      </VStack>
    </MainContentContainer>
  );
};

const ClusterInfoBox = ({ cluster }: { cluster: Cluster }) => {
  let message = null;

  const defaultSystemClusterInfoList = (
    <UnorderedList>
      <ListItem>
        You are <strong>not billed</strong> for this cluster.
      </ListItem>
      <ListItem>You cannot create objects in this cluster.</ListItem>
      <ListItem>You cannot alter or drop this cluster.</ListItem>
      <ListItem>
        You cannot run <code>SELECT</code> or <code>SUBSCRIBE</code> queries in
        this cluster.
      </ListItem>
    </UnorderedList>
  );

  if (cluster.name === "mz_catalog_server") {
    message = (
      <Text>
        This is a built-in system cluster that maintains several indexes to
        speed up <Code>SHOW</Code> commands and queries using the system
        catalog:
        <UnorderedList>
          <ListItem>
            You are <strong>not billed</strong> for this cluster.
          </ListItem>
          <ListItem>You cannot create objects in this cluster.</ListItem>
          <ListItem>You cannot alter or drop this cluster.</ListItem>
          <ListItem>
            You can run <code>SELECT</code> or <code>SUBSCRIBE</code> queries in
            this cluster as long as they only refer to objects in the system
            catalog.
          </ListItem>
        </UnorderedList>
      </Text>
    );
  } else if (cluster.name === "mz_probe") {
    message = (
      <Text>
        This is a built-in system cluster used for internal uptime monitoring:
        {defaultSystemClusterInfoList}
      </Text>
    );
  } else if (cluster.name === "mz_support") {
    message = (
      <Text>
        This is a built-in system cluster used for internal support tasks:
        {defaultSystemClusterInfoList}
      </Text>
    );
  } else if (cluster.name === "mz_system") {
    message = (
      <Text>
        This is a built-in system cluster used for internal system jobs:
        {defaultSystemClusterInfoList}
      </Text>
    );
  } else if (cluster?.replicas.length === 0) {
    message = (
      <Text>
        This cluster is currently inactive. Increase its replication factor to
        resume processing.
      </Text>
    );
  }

  return (
    message && (
      <Alert
        variant="info"
        showLabel={true}
        message={message}
        width="100%"
        mb="5"
      />
    )
  );
};

export default ClusterOverview;
