import { subMinutes } from "date-fns";
import { InferResult, sql } from "kysely";
import React from "react";

import { escapedLiteral as lit, queryBuilder } from "~/api/materialize";
import { buildClusterReplicaUtilizationTable } from "~/api/materialize/expressionBuilders";
import {
  buildSubscribeQuery,
  useRawSubscribe,
} from "~/api/materialize/useSubscribe";

export interface ReplicaUtilization extends Record<string, unknown> {
  id: string;
  name: string;
  size: string | null;
  timestamp: number;
  cpuPercent: number | null;
  memoryPercent: number | null;
  diskPercent: number | null;
  offlineReason: string | null;
  status: string;
}

export function buildClusterUtilizationQuery({
  clusterId,
  replicaId,
  mzClusterReplicaUtilization = "mz_cluster_replica_utilization",
}: {
  clusterId: string;
  replicaId?: string;
  mzClusterReplicaUtilization?: "mz_cluster_replica_utilization";
}) {
  let utilizationQuery = queryBuilder
    .selectFrom("mz_cluster_replicas as cr")
    .innerJoin("mz_cluster_replica_statuses as crs", (join) =>
      join.onRef("crs.replica_id", "=", "cr.id").on("crs.process_id", "=", "0"),
    )
    .innerJoin(
      buildClusterReplicaUtilizationTable({ mzClusterReplicaUtilization }).as(
        "cru",
      ),
      (join) => join.onRef("cr.id", "=", "cru.replica_id"),
    )
    .where("cr.cluster_id", "=", clusterId)
    .select([
      "cr.id",
      "cr.name",
      "cr.size",
      "cru.cpu_percent as cpuPercent",
      "cru.memory_percent as memoryPercent",
      "cru.disk_percent as diskPercent",
      "crs.reason as offlineReason",
      "crs.status",
    ]);
  if (replicaId) {
    utilizationQuery = utilizationQuery.where("crs.replica_id", "=", replicaId);
  }
  return utilizationQuery;
}

export type ClusterUtilizationResult = InferResult<
  ReturnType<typeof buildClusterUtilizationQuery>
>;

export function buildClusterUtilizationSubscribe({
  initialStartTime,
  clusterId,
  replicaId,
  mzClusterReplicaUtilization = "mz_cluster_replica_utilization",
}: {
  initialStartTime: Date;
  clusterId: string;
  replicaId?: string;
  mzClusterReplicaUtilization?: "mz_cluster_replica_utilization";
}) {
  const utilizationQuery = buildClusterUtilizationQuery({
    clusterId,
    replicaId,
    mzClusterReplicaUtilization,
  });
  const subscribeQuery =
    sql`SUBSCRIBE (${utilizationQuery}) WITH (PROGRESS) AS OF AT LEAST TIMESTAMP ${lit(
      new Date(initialStartTime).toISOString(),
    )} ENVELOPE UPSERT (KEY (id))`.compile(queryBuilder);

  return subscribeQuery;
}

const useClusterUtilization = ({
  clusterId,
  replicaId,
  timePeriodMinutes,
}: {
  clusterId: string | undefined;
  replicaId?: string;
  timePeriodMinutes: number;
}) => {
  // When we first set the time period, we pick the current time as the end time for the
  // graph, then work backwards to calculate the initialStartTime based on the selected
  // time period.
  const initialEndTime = React.useMemo(() => new Date().getTime(), []);

  const subscribe = React.useMemo(() => {
    if (!clusterId) return;

    return buildSubscribeQuery(
      buildClusterUtilizationQuery({
        clusterId,
        replicaId,
      }),
      {
        upsertKey: "id",
        asOfAtLeast: subMinutes(initialEndTime, timePeriodMinutes),
      },
    );
  }, [clusterId, initialEndTime, replicaId, timePeriodMinutes]);

  const {
    reset,
    data: rawData,
    snapshotComplete,
    error,
  } = useRawSubscribe<ClusterUtilizationResult[0]>({ subscribe });

  const data = rawData.map((row) => ({
    timestamp: row.mzTimestamp,
    ...row.data,
  }));

  // This is the current end time of the graph
  const currentEndTime = React.useMemo(() => {
    if (data) {
      const newestData = new Date(data[data.length - 1]?.timestamp);
      if (new Date(initialEndTime) < newestData) {
        return new Date(data[data.length - 1]?.timestamp);
      }
    }
    return new Date(initialEndTime);
  }, [data, initialEndTime]);

  // This is the current start time of the graph
  const currentStartTime = React.useMemo(() => {
    return subMinutes(currentEndTime, timePeriodMinutes);
  }, [timePeriodMinutes, currentEndTime]);

  return {
    reset,
    currentEndTime,
    currentStartTime,
    data,
    error,
    snapshotComplete,
    hasAnyData: data.length > 0,
  };
};

export default useClusterUtilization;
